import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import Link from 'gatsby-link';
import { Cookies } from 'react-cookie-consent';

export default function RelatedFeatures({ categories, language }) {
  const data = useStaticQuery(graphql`
  query Features {
    features: allMdx {
      posts: nodes {
        id
        fields {
            slug
        }
        exports {
          categories {
            name
            index
          }
          sections {
            orientation
            title
            icon {
              publicURL
            }
            descriptions
          }
        }
        frontmatter {
          language
          type
          title
          cardTitle
          carouselTitle
          carouselDescription
          chipTitles
          excerpt
          cover {
            publicURL
          }
          coverAlt
        }
      }
    }
  }
`);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1
    }
  };

  const featuresAreRelated = (feature) => {
    if (feature.frontmatter.language !== language) {
      return false;
    }

    if (categories) {
      for (let category1 of categories) {
        for (let category2 of feature.exports.categories) {
          if (category1.name === category2.name) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const relatedFeatures = data?.features?.posts?.filter(x => x.frontmatter.type === "feature" && featuresAreRelated(x));

  return relatedFeatures && relatedFeatures.length && (
    <div className={"flex flex-col w-full px-5 md:px-10 lg:px-20 xl:px-40 bg-gray-100 feature-details-carousel"}>
      <h2 className={"font-light uppercase my-10 lg:text-lg"}>ARTICLES<span className={"font-semibold"}>CONNEXES</span></h2>
      <Carousel responsive={responsive} className={"mb-10"} showDots>
        {relatedFeatures.map((feature, index) => (
          <Link to={feature.fields.slug.replace("/posts", "").replace("/ro/", "/")} className={"w-full"} key={index}>
            <div className={"mx-5 my-5"}>
              <div className={"flex flex-col w-full hover:bg-primary-default text-gray-600 hover:text-white items-center feature-card rounded-xl pt-2 bg-white"}>
                <img loading="lazy" src={feature?.frontmatter?.cover?.publicURL} alt={feature.frontmatter.coverAlt} className={"w-full bg-white card-image"} />
                <div className={"p-2 py-5 w-full flex flex-col bg-gray-200 details-feature-card-text rounded-b-xl"}>
                  <div>
                    <h3 className={"text-sm uppercase font-semibold pb-2"}>{feature.frontmatter.cardTitle}</h3>
                    <p className={"text-xs font-semibold"}>{feature.frontmatter.category}</p>
                    <p className={"text-xs pb-2 font-semibold text-gray-500 feature-more"}>{feature?.exports?.categories?.map((category, index) => category.name + (index < feature.exports.categories.length - 1 ? ", " : ""))}</p>
                  </div>
                  <p className={"text-sm xl:text-md pb-2"}>{feature?.frontmatter?.excerpt} <span className={"text-primary-blockquote feature-more"}>(...plus)</span> {feature?.whiteSpace ? <pre>{feature.whiteSpace}</pre> : null}</p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </Carousel>
    </div>
  );
}
